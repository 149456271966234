<template>
    <v-card :rounded="$config.shaped_widgets" class="widget" :loading="loading">
        <v-card-title flat style="padding-bottom: 0px !important;">
            <v-card-title class="mb-0 py-0">{{ $t("requestOverview") }}</v-card-title>
            <v-spacer></v-spacer>
           <!-- we make a vuetify input list with past 10 years and current year -->
            <v-select
                v-model="year"
                :items="Array.from({ length: 6 }, (v, k) => new Date().getFullYear() - 5 + k)"
                label="Jaar"
                outlined
                dense
                @change="getRequests"
                class="mx-4"
            ></v-select>
        </v-card-title>

        <v-card-text style="padding-top: 0px !important; padding-bottom: 0px !important;">
            <span class="d-block ml-5 mr-5 mt-1">
                <v-alert
                    style="cursor: pointer"
                    @click="selectApprovalStatus('OPEN')"
                    :icon="false"
                    color="grey"
                    dense
                    text
                >
                    <v-row>
                        <v-col class="shrink">
                            <v-avatar size="25" color="grey">
                                <h6 class="white--text">
                                    {{ open }}
                                </h6>
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <small>
                                <span>aanvragen open</span>
                            </small>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-alert
                    style="cursor: pointer"
                    @click="selectApprovalStatus('IN_PROGRESS')"
                    :icon="false"
                    type="info"
                    dense
                    text
                >
                    <v-row>
                        <v-col class="shrink">
                            <v-avatar size="25" color="info">
                                <h6 class="white--text">
                                    {{ inProgress }}
                                </h6>
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <small>
                                <span>aanvragen in behandeling</span>
                            </small>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-alert
                    style="cursor: pointer"
                    @click="selectApprovalStatus('APPROVED')"
                    :icon="false"
                    type="success"
                    dense
                    text
                >
                    <v-row>
                        <v-col class="shrink">
                            <v-avatar size="25" color="success">
                                <h6 class="white--text">
                                    {{ approved }}
                                </h6>
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <small>
                                <span>aanvragen goedgekeurd</span>
                            </small>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-alert
                    style="cursor: pointer"
                    @click="selectApprovalStatus('DISAPPROVED')"
                    :icon="false"
                    type="error"
                    dense
                    text
                >
                    <v-row>
                        <v-col class="shrink">
                            <v-avatar size="25" color="error">
                                <h6 class="white--text">
                                    {{ disapproved }}
                                </h6>
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <small>
                                <span>{{ $t("aanvragen afgewezen") }}</span>
                            </small>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-alert
                    style="cursor: pointer"
                    @click="selectApprovalStatus('FAILED')"
                    :icon="false"
                    color="orange"
                    dense
                    text
                >
                    <v-row>
                        <v-col class="shrink">
                            <v-avatar size="25" color="orange">
                                <h6 class="white--text">
                                    {{ failed }}
                                </h6>
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <small>
                                <span>{{ $t("aanvragen foutief") }}</span>
                            </small>
                        </v-col>
                    </v-row>
                </v-alert>
            </span>
        </v-card-text>
        <v-footer class="pa-5"> </v-footer>
    </v-card>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            requests: [],
            loading: false,
        };
    },
    created() {
        this.getRequests();
    },
    methods: {
        getRequests() {
            this.loading = true;
            this.$http.get(this.$config.backend_url + "/care-requests?approval-filter=ALL&year=" + this.year).then((response) => {
                this.requests = response.data;
                this.loading = false;
            });
        },
        async selectApprovalStatus(approvalStatus) {
            // Dispatch the action to update the approval status
            await this.$store.dispatch("requestIndexSettings/updateSettings", {
                // Assuming you want to keep other settings as they are, you might need to pass them along too
                search: this.$store.state.requestIndexSettings.search,
                itemsPerPage: this.$store.state.requestIndexSettings.itemsPerPage,
                page: this.$store.state.requestIndexSettings.page,
                approvalFilter: [approvalStatus], // Set the new approval status
                extensionFilter: this.$store.state.requestIndexSettings.extensionFilter, // Keep existing
            });

            // Navigate to the index page after the state has been updated
            this.$router.push({ name: "requests" });
        },
    },
    computed: {
        open() {
            let requests = this.requests.filter((request) => request.approval_status == "OPEN");
            if (requests) {
                return requests.length;
            }
            return 0;
        },
        approved() {
            let requests = this.requests.filter((request) => request.approval_status == "APPROVED");
            if (requests) {
                return requests.length;
            }
            return 0;
        },
        disapproved() {
            let requests = this.requests.filter((request) => request.approval_status == "DISAPPROVED");
            if (requests) {
                return requests.length;
            }
            return 0;
        },
        inProgress() {
            let requests = this.requests.filter((request) => request.approval_status == "IN_PROGRESS");
            if (requests) {
                return requests.length;
            }
            return 0;
        },
        failed() {
            let requests = this.requests.filter((request) => request.approval_status == "FAILED");
            if (requests) {
                return requests.length;
            }
            return 0;
        },
        year: {
            get() {
                return this.$store.getters.getSelectedYear;
            },
            set(value) {
                this.$store.dispatch("setSelectedYear", value);
            },
        },
    },
};
</script>

<style>
.top-up-section {
    width: 200px;
    display: inline-block;
}
</style>
